$font-bold: NotoSans-SemiBold, NotoSansThai-SemiBold;
$font-Regular: NotoSansThai-Regular, NotoSans-Regular;
$font-Medium: NotoSansThai-Medium, NotoSans-Medium;
$font-light: NotoSansThai-Light, NotoSans-Light;

$black: #292929;
$gray: #717275;
$gray-light: #dee2e6;
$strcyan: #994000;
$cyan: #994000;
$lightcyan: #DB5A00;
$yellow: #f6cd64;
$red: #ff4e4d;
$link-color: #292929;
$link-hover-color: #DB5A00;

$color-1: #DB5A00;
$color-2: #ffffff;
$color-3: #DB5A00;
$color-4: #ffffff;
$color-5: #F4F4F4;
// $color-6: #DEE2E6;
$color-6: #ed9907;
$color-7: #d4d7e1;
// $color-8: #000000;
$color-8: #F9A30B;
$color-9: #DB5A00;
$color-10: #DB5A00;
$color-11: #f0bf43;
$color-12: #994000;
$color-13: #FFFBEE;
$color-14: #EAEEF6;
$color-15: #ffffff;
$color-16: #ffffff;
$color-17: #F9A30B;
$color-18: #EAEEF3;
$color-19: #DEE2E6;
$color-20: #DB5A00;
$color-21: #B34A00;
$color-22: #FFE9DB;
$color-23: #000000;
$color-24: #DEE2E6;
$color-25: #D5D8DC;
$color-26: #EAECEE;
$color-27: #717275;
$color-28: #ED9907;

.red {
  color: $red;
}

.btn-normal {
  background-color: #DB5A00;
  border-radius: 8px;
  color: #FFFFFF;
  border: none;

  &:hover {
    background-color: #b34a00;
    border: none;
  }
}

.remove-m-p {
  p {
    margin: 0 !important;
    font-family: $font-Regular !important;
  }

  span {
    font-family: $font-Regular !important;
  }
}

.text-color-2 {
  color: $color-3 !important
}

.text-color-12 {
  color: $color-12 !important
}

.btn-1 {
  color: $color-2 !important;
  border-color: $color-3 !important;
  background-color: $color-3 !important;
}

.btn-1:hover {
  border-color: $color-1 !important;
  // background-color: $color-1 !important;
}

.txt-primary {
  color: $color-10;
}

.txt-secondary {
  color: $color-27;
}

.btn-20 {
  color: #ffffff;
  border-color: $color-3 !important;
  background-color: $color-3 !important;
}

.btn-20:hover {
  border-color: $color-21 !important;
  background-color: $color-21 !important;
}

.btn-2 {
  color: $color-4 !important;
  border-color: $color-6 !important;
  background-color: $color-2 !important;
  width: 300px;
}

.btn-2:hover {
  border-color: $color-7 !important;
  background-color: $color-5 !important;
}

.btn-3 {
  color: $color-4 !important;
  border: 1px solid $color-6 !important;
  background-color: $color-2 !important;
}

.btn-3:hover {
  color: $color-8 !important;
  border: 1px solid $color-8 !important;
  background-color: $color-2 !important;
}

.btn-component-4 {
  border-radius: "4px" !important;
  border: "1px solid" !important;
  width: "70px" !important;
  height: "20px" !important;
}

.btn-4 {
  color: $color-15 !important;
  border: 1px solid $color-8 !important;
  background-color: $color-8 !important;
  box-shadow: none !important;
}

.btn-4:hover {
  color: $color-4 !important;
  background-color: $color-6 !important;
}

.btn-5 {
  color: $color-4 !important;
  border: 1px solid $color-3 !important;
  background-color: $color-3 !important;
}

.btn-5:hover {
  border: 1px solid $color-21 !important;
  background-color: $color-21 !important;
  box-shadow: none !important;
}

.btn-6 {
  color: $color-4 !important;
  border: 1px solid $color-6 !important;
  background-color: $color-6 !important;
}

.btn-6:hover {
  background-color: $color-8 !important;
  ;
}

.btn-7 {
  color: $color-4 !important;
  border: 1px solid $color-11 !important;
  background-color: $color-12 !important;
}

.btn-7:hover {
  color: $color-4 !important;
  background-color: $color-12 !important;
  box-shadow: none !important;
}

.btn-8 {
  color: $color-4 !important;
  border: 1px solid $color-6 !important;
  background-color: $color-2 !important;
}

.btn-9 {
  color: $color-12 !important;
  border: 1px solid $color-12 !important;
  background-color: $color-13 !important;
}

.btn-10 {
  color: $color-20 !important;
  border: 1px solid $color-20 !important;
  background-color: $color-2 !important;
}

.btn-10:hover {
  color: $color-20 !important;
  // background-color: $color-22 !important;
}

.btn-11 {
  color: $color-4 !important;
  border-color: $color-5 !important;
  background-color: $color-2 !important;
}

.btn-11:hover {
  color: $color-4 !important;
  border-color: $color-5 !important;
  background-color: $color-5 !important;
}

.btn-12 {
  color: $color-4 !important;
  border: 1px solid $color-6 !important;
  background-color: $color-6 !important;
}

.btn-12:hover {
  background-color: $color-14 !important;
  box-shadow: none !important;
}

.btn-13 {
  color: $color-4 !important;
  border: 1px solid $color-8 !important;
  background-color: $color-8 !important;
  box-shadow: none !important;
}

.btn-13:hover {
  border: 1px solid $color-12 !important;
  background-color: $color-12 !important;
}

.btn-16 {
  color: $color-15 !important;
  // border: 1px solid $color-8 !important;
  background-color: $color-8 !important;
  box-shadow: none !important;
}

.btn-16:hover {
  color: $color-16 !important;
  background-color: $color-12 !important;
}

.btn-login {
  color: #ffffff !important;
  // border: 1px solid $color-8 !important;
  background-color: #DB5A00 !important;
  box-shadow: none !important;
}

.btn-login:hover {
  background-color: #B34A00 !important;
}

.btn-17 {
  color: $color-23 !important;
  border: 1px solid $color-19 !important;
  box-shadow: none !important;
}

.btn-17:hover {
  color: $color-20 !important;
  border-color: $color-3 !important;
  background-color: $color-2 !important;
}

.btn-18 {
  color: $black !important;
  border: 1px solid $color-6 !important;
  background-color: $color-2 !important;
}

.btn-18:hover {
  color: $color-8 !important;
  border: 1px solid $color-8 !important;
  background-color: $color-2 !important;
}

.btn-19 {
  color: $color-15 !important;
  border: 1px solid $color-17 !important;
  background-color: $color-17 !important;
}

.btn-19:hover {
  color: $color-15 !important;
  border: 1px solid $color-11 !important;
  background-color: $color-11 !important;
}


.btn-21 {
  color: $color-15 !important;
  border: 1px solid $color-17 !important;
  background-color: $color-17 !important;
}

.btn-21:hover {
  color: $color-15 !important;
  border: 1px solid $color-11 !important;
  background-color: $color-11 !important;
}

.btn-cancle {
  color: $color-23 !important;
  border: 1px solid $color-25 !important;
  background-color: $color-25 !important;
  padding: 10px 60px;
}

.btn-cancle:hover {
  color: $color-23 !important;
  border: 1px solid $color-26 !important;
  background-color: $color-26 !important;
}

.btn-cancleDisable {
  color: $color-27 !important;
  border: 1px solid $color-25 !important;
  background-color: $color-25 !important;
  padding: 10px 60px;
}

.btn-cancleDisable:hover {
  color: $color-23 !important;
  border: 1px solid $color-26 !important;
  background-color: $color-26 !important;
}


.btn-success {
  color: $color-15 !important;
  border: 1px solid $color-20 !important;
  background-color: $color-20 !important;
  padding: 10px 60px;
}

.btn-success:hover {
  color: $color-15 !important;
  border: 1px solid $color-12 !important;
  background-color: $color-12 !important;
}

.btn-openApp {
  color: $color-1 !important;
  border: 1px solid $color-2 !important;
  background-color: $color-2 !important;
  border-radius: 24px;

}

.btn-openApp:hover {
  color: $color-15 !important;
  border: 1px solid $color-2 !important;
  background-color: $color-2 !important;
}


.btn-coupon {
  border-color: #DB5A00;
  background-color: #DB5A00;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: 2rem;
  margin-top: 10px;
  white-space: nowrap;
  width: 50px;


}

.btn-coupon:hover {
  border-color: #b34a00;
  background-color: #b34a00;
}


.btn-coupon-disable {
  border-color: 1px solid #DEE2E6;
  background-color: #DEE2E6;
  color: #000000;
  cursor: pointer;
  margin-left: 2rem;
  margin-top: 10px;
  white-space: nowrap;
  width: 50px;


}

.btn-coupon-disable:hover {
  border-color: 1px solid #DEE2E6;
  background-color: #EAEEF2;
}

.btn-Evoucher {
  color: $color-15 !important;
  border: 1px solid #DB5A00 !important;
  background-color: #DB5A00 !important;
  padding: 10px 60px;
}

.btn-Evoucher:hover {
  color: $color-15 !important;
  border: 1px solid #b34a00 !important;
  background-color: #b34a00 !important;
}

.btn-Evoucher-detail {
  color: #292929;
  border: 1px solid #DEE2E6;
  background-color: transparent;
  padding: 10px 60px;

  &:hover {
    border: 1px solid #DEE2E6;
    background-color: #F4F4F4;
  }
}

.map-btn {
  color: $black !important;
  border: 1px solid $color-6 !important;
  background-color: #ffffff !important;
}

.map-btn:hover {
  color: $color-8 !important;
  border: 1px solid #DB5A00 !important;
  color: #DB5A00 !important;
  background-color: #ffffff !important;
}

.link-memu {
  color: $black !important;
}

.link-memu:hover {
  color: #DB5A00 !important;
}


/* styles.css */

.colorFrom .MuiOutlinedInput-root {
  border-color: #1774d1 !important;
  color: $color-3 !important;
}

.colorFrom .MuiSelect-select {
  color: #000000 !important;
}

.colorFrom .MuiOutlinedInput-notchedOutline {
  border-color: #DEE2E6 !important;

}

.colorFrom .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #DB5A00 !important;
  /* สีแดง */
}

/* เปลี่ยนสีขอบเมื่อ Select ถูก Focus */
.colorFrom .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #DB5A00 !important;
  // background-color: #DB5A00;
  /* สีแดง */
}

/* เพิ่ม :focus-within สำหรับกรณีที่ parent hover มีผล */
.colorFrom:hover .MuiOutlinedInput-notchedOutline,
.colorFrom:focus-within .MuiOutlinedInput-notchedOutline {
  border-color: #FF0000 !important;
  /* สีแดง */
}

/* เปลี่ยนสีของตัวหนังสือเมื่อ Hover และ Focus */
.colorFrom .MuiSelect-select:hover {
  color: #DB5A00 !important;
  /* สีแดง */
}

.colorFrom .MuiSelect-select:focus {
  color: #292929 !important;
  /* สีแดง */
}

.btn-pin-success {
  color: $color-4 !important;
  border: 1px solid $color-8 !important;
  background-color: $color-8 !important;
  box-shadow: none !important;
  padding: 10px 20px !important;
}

.btn-pin-success:hover {
  color: $color-4 !important;
  background-color: $color-12 !important;
}

.btn-pin-cancel {
  color: $color-4 !important;
  border: 1px solid $color-6 !important;
  background-color: $color-6 !important;
  box-shadow: none !important;
  padding: 10px 20px !important;
}

.btn-pin-cancel:hover {
  color: $color-4 !important;
  background-color: $color-6 !important;
}

.bac-1 {
  background-color: $color-5 !important;

}

.text-color-1 {
  color: $color-4 !important;
}

.text-color-1:hover {
  color: $color-3 !important;
  font-family: $font-bold !important;
}

.text-color-4 {
  color: $color-4 !important;
}


.text-color-4 {
  color: $color-4 !important;
}

.text-color-5 {
  color: $black !important;
}

.text-color-5:hover {
  color: $color-3 !important;
}

.swal-padding {
  padding: '1rem 3rem 3rem 3rem'
}

.black {
  color: $black !important;
}

.gray {
  color: $gray !important;
}

.gray-light {
  color: $gray-light !important;
}

.strcyan {
  color: $strcyan !important;
}

.cyan {
  color: $cyan !important;
}

.lightcyan {
  color: $lightcyan !important;
}

.yellow {
  color: $yellow !important;
}

.bg-lightcyan {
  background-color: $lightcyan !important;
}

.bg-white {
  background-color: #FFFFFF;
}

.text-ellipsis-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.text-ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

hr {
  color: #a8a8a8;
}

.btn-disabled {
  color: $gray !important;
  border: 1px solid $color-24 !important;
  background-color: $color-24 !important;
}

.text-hover-tran {
  color: $color-4 !important;
  text-decoration: none !important;
}

.text-hover-tran:hover {
  font-family: $font-bold !important;
}


.width-100 {
  width: 100% !important;
}

.d-flex-align-center-profile {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  font-size: 25px !important;
  font-family: $font-bold !important;
}

.grayscale {
  filter: grayscale(1);
}

$fontSize: (
  "lg": 48,
  "md": 32,
  "sm": 24,
  "xs": 16,
  "ss": 14,
);

@function getFontSize($block) {
  @return map-get($fontSize, $block);
}

/* example "@include font-size(getFontSize('large'));" */
@mixin font-size($size) {
  font-size: $size + px;
  // font-size: ($size / 10) + rem;
}

:root {
  color-scheme: light only;
}

body {
  font-family: $font-Regular;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
  @include font-size(getFontSize("ss"));
  margin: 0;
  overflow-x: hidden;

  a {
    text-decoration: none;
  }

  .form-control:focus {
    box-shadow: unset;
  }
}

.container-spacing {
  max-width: 2560px;
  padding-left: 80px;
  padding-right: 80px;

  @include max-screen(1920px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include max-screen(1600px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include max-screen(1360px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include max-screen(899px) {
    // max-width: 800px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.inline-block {
  display: inline-block;
}

@include placeholder {
  @include font-size(getFontSize("xs"));
  font-family: $font-Regular;
}

// Links

a {
  color: $link-color;
  text-decoration: unset;
  transition: 0.25s;

  &:hover {
    color: $link-hover-color;
    text-decoration: unset;
  }
}

.grayscale {
  opacity: .8;
  filter: grayscale(1);
}

.ignore-hover {
  color: initial;
}

.text-not-hover {
  color: $link-color;

  &:hover {
    color: $link-color !important;
  }
}

.font-Regular {
  font-family: $font-Regular !important;
}

.txt-med {
  font-family: $font-Medium !important;
}

.txt-bold {
  font-family: $font-bold !important;
}

.img_data img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.txt-default-responsive p {
  font-size: 16px;

  @include max-screen(603px) {
    font-size: 14px;
  }
}



.fontSize-xs {
  font-size: 16px !important;

}

.fontSize-12 {
  font-size: 12px !important;
}

.fontSize-14 {
  font-size: 14px !important;
}

.fontSize-20 {
  font-size: 20px !important;
}

.fontSize-25 {
  font-size: 25px !important;
}

.txt-title {
  font-family: $font-bold;
  font-size: 25px;
  color: $black;

  @include max-screen(603px) {
    font-size: 20px;
  }
}

.txt-sub-title {
  font-family: $font-bold;
  font-size: 20px;
  color: $black;

  @include max-screen(603px) {
    font-size: 16px;
  }
}

.text-close {
  color: "#717275";
  text-align: "right";
  cursor: "pointer";
  font-size: "20px";
}

// .txt-SemiBold {
//   font-family: $NotoSansThai-SemiBold;
// }

// Clamps a block of text to a certain number of lines,
// followed by an ellipsis in Webkit and Blink based browsers
@mixin text-clamp($lines: 2, $line-height: false) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;

  // Fallback for non-Webkit browsers
  // (won't show `…` at the end of the block)
  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}

.floating-element {
  position: relative;
  /* Common styles for the floating element */
}

.floating-element.scrolled {
  /* Styles specific to the floating element when scrolled */
  position: sticky;
  top: 20px;

  @include max-screen(603px) {
    position: relative;
    top: 0;
  }

  /* Adjust the desired distance from the top */
  // z-index: 999;
  /* Additional styling for the floating element */
}

.bgToggleButton {
  background-color: #F6CD64 !important;
}

.img-thumb-responsive {
  height: auto;
  width: 100%;
  min-height: 204px;
  max-height: 204px;
  object-fit: contain;

  @include max-screen(1024px) {
    min-height: 378px;
  }

  @include max-screen(800px) {
    min-height: 170px;
    max-height: 170px;
  }
}

.borderTextField .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6;
}

.borderTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #DB5A00;
}

.borderTextField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #DB5A00;
}

.no-hover:hover {
  background-color: transparent;
  box-shadow: none;
}

// sx= {
//     {
//     fontSize: '14px', display: 'flex', alignItems: 'center', pl: "22px", position:"relative"
//   }
// }
.customtextEvoucher {
 
  display: flex;
  padding-left: 22px !important;
  position: relative;


  p {
    font-family: $font-Regular !important;
    font-size: 14px !important;
  }
}

.customtextDetail {
  display: flex;
    padding-left: 18px !important;
    position: relative;
  
  
    p {
      font-family: $font-Regular !important;
      font-size: 12px !important;
    }
}