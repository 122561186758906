.btn-to-seller {
  border-radius: 16px;
  color: #FFF;
  background-color: #DB5A00;
  padding: .25rem .5rem;
  margin-left: .5rem;

  &:hover {
    color: #FFF;
    background-color: #b34a00;
  }
}


.cs-navbar {
  background-color: white;
  padding: 0 !important;
  // border-bottom: 1px solid $gray-light;
  // padding-top: 1rem;
  // padding-bottom: 1rem;

  .navbar-nav {
    a {
      font-family: $font-Regular;
      color: $black !important;
      padding: 0 0.5rem;

      &:hover {
        color: $yellow !important;
      }
    }
  }

  .navbar-toggler {
    border: 0;
  }

  @include max-screen(533px) {
    .navbar-collapse {
      padding-top: 1rem;
    }
  }
}

.offcanvas-header,
.offcanvas-body {
  @include max-screen(533px) {
    background-color: $cyan;
    a {
      padding: 0.5rem 0;
    }
  }
}

.navbar-petsploy {
  padding-top: 0;
  padding-bottom: 0;
  // margin-right: 0;
  // position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  background-color: white;
  // box-shadow: 0 4px 2px -2px rgb(149 157 165 / 20%);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  // box-shadow: inset 0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(222 222 222 / 8%), 0px 1px 10px 0px rgb(0 0 0 / 6%);
  header {
    background-color: transparent;
    box-shadow: unset;
  }
  .nav-link {
    font-family: NotoSansThai-Regular, NotoSans-Regular;
    color: $black !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    &:hover {
      color: $yellow !important;
    }
  }
  @include max-screen(533px) {
    .px-4 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .navbar-brand {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-toggler {
    margin-right: 1rem;
  }
  @include max-screen(533px) {
    .navbar-collapse {
      background-color: $yellow;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
}
.navbar-top {
  background-color: #F5F6F8;
  header {
    background-color: transparent;
    box-shadow: unset;
    .css-hyum1k-MuiToolbar-root {
      min-height: 44px;
    }
  }
}


// .MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list{
//   margin: 0px !important;
//   padding: 0px !important;
//   top: 0px !important;
//   right: 0px !important;
// }
// .css-46bh2p-MuiCardContent-root{
//   margin: 0px !important;
//   padding: 0px !important;
// }

.pagination{
display: block;
justify-content: center;
text-align: center;
color: $color-8;
}

.pagination:hover {
  color: $color-22 !important;
  // background-color: $color-22 !important;
}
.store {
  display: block;
  justify-content: center;
  text-align: center;
  color: #000000;
}

.store:hover {
  color: #DB5A00 !important;
  border: 1px solid  #DB5A00 !important;
  // background-color: $color-22 !important;
}

.paginationProfile{
  display: flex;
justify-content: end;
color: $color-3;
}

.css-19kzrtu {
  padding: 0px !important;
}
.PaginationEvoucher{
  justify-content: end;
   display: flex;
   margin-top: 6px;
}
